import axios from 'axios';

const instance2 = axios.create();

instance2.defaults.baseURL = process.env.REACT_APP_API_URL;
// instance2.defaults.timeout = 5000;
instance2.defaults.headers.post['Content-Type'] = 'application/json';
instance2.defaults.headers.post['Accept'] = 'application/json';


export default instance2;
