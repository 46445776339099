import './accountMock';
import './searchMock';
import './notificationsMock';
import './reportsMock';
import './managementMock';
import './calendarMock';
import './chatMock';
import './kanbanMock';
import './mailMock';
import './projectMock';
import './socialMock';
