import AxiosMockAdapter from 'axios-mock-adapter';
import axios from './axios';

let instance = null;
// if (process.env.REACT_APP_ENV === 'testing') {
//   instance = new AxiosMockAdapter(axios, { delayResponse: 0 });
// } else {
//   instance = new AxiosMockAdapter(null, { delayResponse: 0 });
// }
instance = new AxiosMockAdapter(axios, { delayResponse: 0 });

export default instance;
