/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useEffect } from 'react';
import { useLocation, matchPath } from 'react-router';
import { Link as RouterLink } from 'react-router-dom';
import {connect, useSelector} from 'react-redux';
import PerfectScrollbar from 'react-perfect-scrollbar';
import PropTypes from 'prop-types';
import {
  Avatar,
  Box,
  Chip,
  Divider,
  Drawer,
  Hidden,
  Link,
  List,
  ListSubheader,
  Typography,
  makeStyles, Button, CardContent
} from '@material-ui/core';
import ReceiptIcon from '@material-ui/icons/ReceiptOutlined';
import {
  Briefcase as BriefcaseIcon,
  Calendar as CalendarIcon,
  ShoppingCart as ShoppingCartIcon,
  Folder as FolderIcon,
  BarChart as BarChartIcon,
  Lock as LockIcon,
  UserPlus as UserPlusIcon,
  Shield as ShieldIcon,
  AlertCircle as AlertCircleIcon,
  Trello as TrelloIcon,
  User as UserIcon,
  Layout as LayoutIcon,
  Edit as EditIcon,
  DollarSign as DollarSignIcon,
  Mail as MailIcon,
  MessageCircle as MessageCircleIcon,
  PieChart as PieChartIcon,
  Share2 as ShareIcon,
  Users as UsersIcon,
  List as ListIcon
} from 'react-feather';
import Logo from 'src/components/Logo';
import NavItem from './NavItem';
import {bindActionCreators} from "redux";
var _ = require('lodash');

const navConfig = [
  {
    subheader: 'Reports',
    items: [
      {
        title: 'Dashboard',
        icon: BarChartIcon,
        href: '/app/rp/dashboard'
      },
      {
        title: 'By Payment',
        icon: ListIcon,
        href: '/app/rp/sales-by-payments'
      },
      {
        title: 'By Menu',
        icon: ListIcon,
        href: '/app/rp/sales-by-menus'
      },
      {
        title: 'By Order',
        icon: ListIcon,
        href: '/app/rp/sales-by-orders'
      },
      {
        title: 'By Hours',
        icon: ListIcon,
        href: '/app/rp/sales-by-hours'
      },
      {
        title: 'Payroll',
        icon: ListIcon,
        href: '/app/rp/payroll-timecard'
      },
      {
        title: 'Tax Summary',
        icon: ListIcon,
        href: '/app/rp/tax-summary'
      },
    ]
  },
  // aviBar Hide
  // {
  //   subheader: 'Management',
  //   items: [
  //     {
  //       title: 'Basic',
  //       icon: FolderIcon,
  //       // href: '/app/poble/sales',
  //       items: [
  //         {
  //           title: 'Sales By Hours',
  //           href: '/app/rp/sales-by-hours'
  //         },
  //         {
  //           title: 'Sales By Menus',
  //           href: '/app/rp/sales-by-menus'
  //         },
  //         {
  //           title: 'Sales By Orders',
  //           href: '/app/rp/sales-by-orders'
  //         },
  //         {
  //           title: 'Sales By Payments',
  //           href: '/app/rp/sales-by-payments'
  //         },
  //         // {
  //         //   title: 'Poble Sales List',
  //         //   href: '/app/poble/sales'
  //         // },
  //         // {
  //         //   title: 'Poble Item Count List',
  //         //   href: '/app/poble/item-counts'
  //         // },
  //         // {
  //         //   title: 'Poble Payment List',
  //         //   href: '/app/poble/payments'
  //         // },
  //         // {
  //         //   title: 'eFranchise List Sales',
  //         //   href: '/app/efranchise/sales'
  //         // },
  //         // {
  //         //   title: 'eFranchise List Productions',
  //         //   href: '/app/efranchise/productions'
  //         // },
  //         // {
  //         //   title: 'View Sales',
  //         //   href: '/app/management/sales/1'
  //         // }
  //       // {
  //       //   title: 'Customers',
  //       //   icon: UsersIcon,
  //       //   href: '/app/management/customers',
  //       //   items: [
  //       //     {
  //       //       title: 'List Customers',
  //       //       href: '/app/management/customers'
  //       //     },
  //       //     {
  //       //       title: 'View Customer',
  //       //       href: '/app/management/customers/1'
  //       //     },
  //       //     {
  //       //       title: 'Edit Customer',
  //       //       href: '/app/management/customers/1/edit'
  //       //     }
  //       //   ]
  //       // },
  //       // {
  //       //   title: 'Products',
  //       //   icon: ShoppingCartIcon,
  //       //   href: '/app/management/products',
  //       //   items: [
  //       //     {
  //       //       title: 'List Products',
  //       //       href: '/app/management/products'
  //       //     },
  //       //     {
  //       //       title: 'Create Product',
  //       //       href: '/app/management/products/create'
  //       //     }
  //       //   ]
  //       // },
  //       ]
  //     },
  //     // {
  //     //   title: 'Orders',
  //     //   icon: FolderIcon,
  //     //   href: '/app/management/orders',
  //     //   items: [
  //     //     {
  //     //       title: 'List Orders',
  //     //       href: '/app/management/orders'
  //     //     },
  //     //     {
  //     //       title: 'View Order',
  //     //       href: '/app/management/orders/1'
  //     //     }
  //     //   ]
  //     // },
  //     // {
  //     //   title: 'Invoices',
  //     //   icon: ReceiptIcon,
  //     //   href: '/app/management/invoices',
  //     //   items: [
  //     //     {
  //     //       title: 'List Invoices',
  //     //       href: '/app/management/invoices'
  //     //     },
  //     //     {
  //     //       title: 'View Invoice',
  //     //       href: '/app/management/invoices/1'
  //     //     }
  //     //   ]
  //     // }
  //   ]
  // },

  // {
  //   subheader: 'Applications',
  //   items: [
  //     {
  //       title: 'Projects Platform',
  //       href: '/app/projects',
  //       icon: BriefcaseIcon,
  //       items: [
  //         {
  //           title: 'Overview',
  //           href: '/app/projects/overview'
  //         },
  //         {
  //           title: 'Browse Projects',
  //           href: '/app/projects/browse'
  //         },
  //         {
  //           title: 'Create Project',
  //           href: '/app/projects/create'
  //         },
  //         {
  //           title: 'View Project',
  //           href: '/app/projects/1'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Social Platform',
  //       href: '/app/social',
  //       icon: ShareIcon,
  //       items: [
  //         {
  //           title: 'Profile',
  //           href: '/app/social/profile'
  //         },
  //         {
  //           title: 'Feed',
  //           href: '/app/social/feed'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Kanban',
  //       href: '/app/kanban',
  //       icon: TrelloIcon
  //     },
  //     {
  //       title: 'Mail',
  //       href: '/app/mail',
  //       icon: MailIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Chat',
  //       href: '/app/chat',
  //       icon: MessageCircleIcon,
  //       info: () => (
  //         <Chip
  //           color="secondary"
  //           size="small"
  //           label="Updated"
  //         />
  //       )
  //     },
  //     {
  //       title: 'Calendar',
  //       href: '/app/calendar',
  //       icon: CalendarIcon
  //     },
  //   ]
  // },
  // {
  //   subheader: 'Auth',
  //   items: [
  //     {
  //       title: 'Login',
  //       href: '/login-unprotected',
  //       icon: LockIcon
  //     },
  //     {
  //       title: 'Register',
  //       href: '/register-unprotected',
  //       icon: UserPlusIcon
  //     },
  //     {
  //       title: 'Login: Guest Protected',
  //       href: '/login',
  //       icon: ShieldIcon
  //     },
  //     {
  //       title: 'Register: Guest Protected',
  //       href: '/register',
  //       icon: ShieldIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Pages',
  //   href: '/app/pages',
  //   items: [
  //     {
  //       title: 'Account',
  //       href: '/app/account',
  //       icon: UserIcon
  //     },
  //     {
  //       title: 'Error',
  //       href: '/404',
  //       icon: AlertCircleIcon
  //     },
  //     {
  //       title: 'Pricing',
  //       href: '/pricing',
  //       icon: DollarSignIcon
  //     }
  //   ]
  // },
  // {
  //   subheader: 'Extra',
  //   items: [
  //     {
  //       title: 'Charts',
  //       href: '/app/extra/charts',
  //       icon: BarChartIcon,
  //       items: [
  //         {
  //           title: 'Apex Charts',
  //           href: '/app/extra/charts/apex'
  //         }
  //       ]
  //     },
  //     {
  //       title: 'Forms',
  //       href: '/app/extra/forms',
  //       icon: EditIcon,
  //       items: [
  //         {
  //           title: 'Formik',
  //           href: '/app/extra/forms/formik'
  //         },
  //         {
  //           title: 'Redux Forms',
  //           href: '/app/extra/forms/redux'
  //         },
  //       ]
  //     },
  //     {
  //       title: 'Editors',
  //       href: '/app/extra/editors',
  //       icon: LayoutIcon,
  //       items: [
  //         {
  //           title: 'DraftJS Editor',
  //           href: '/app/extra/editors/draft-js'
  //         },
  //         {
  //           title: 'Quill Editor',
  //           href: '/app/extra/editors/quill'
  //         }
  //       ]
  //     }
  //   ]
  // }
];

const useStyles = makeStyles(() => ({
  mobileDrawer: {
    width: 256
  },
  desktopDrawer: {
    width: 256,
    top: 64,
    height: 'calc(100% - 64px)'
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64
  }
}));

function NavBar({ openMobile, onMobileClose, }) {
  const classes = useStyles();
  const location = useLocation();
  const { user } = useSelector((state) => state.account);


  const renderNavItems = ({ items, ...rest }) => {
    return (
      <List disablePadding>
        {items.reduce(
          (acc, item) => reduceChildRoutes({ acc, item, ...rest }),
          []
        )}
      </List>
    );
  }

  const reduceChildRoutes = ({ acc, pathname, item, depth = 0 }) => {
    if (!user.poble_company_info_id) {
      if (item.title === 'eFranchise List Sales' || item.title === 'eFranchise List Productions') {
        return acc;
      }
    }
    if (!user.franchise_comp_id) {
      if (item.title === 'eFranchise List Sales' || item.title === 'eFranchise List Productions') {
        return acc;
      }
    }

    const key = item.title + depth;
    if (item.items) {
      const open = matchPath(pathname, {
        path: item.href,
        exact: false
      });

      acc.push(
        <NavItem
          depth={depth}
          icon={item.icon}
          key={key}
          info={item.info}
          open={Boolean(open)}
          title={item.title}
        >
          {renderNavItems({
            depth: depth + 1,
            pathname,
            items: item.items
          })}
        </NavItem>
      );
    } else {
      acc.push(
        <NavItem
          depth={depth}
          href={item.href}
          icon={item.icon}
          key={key}
          info={item.info}
          title={item.title}
        />
      );
    }

    return acc;
  }

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    // eslint-disable-next-line
  }, [location.pathname]);

  const content = (
    <Box
      height="100%"
      display="flex"
      flexDirection="column"
    >
      <PerfectScrollbar options={{ suppressScrollX: true }}>
        <Hidden lgUp>
          <Box
            p={2}
            display="flex"
            justifyContent="center"
          >
            <RouterLink to="/">
              <Logo />
            </RouterLink>
          </Box>
        </Hidden>
        <Box p={2}>
          <Box
            display="flex"
            justifyContent="center"
          >
            {/*<RouterLink to="/app/account">*/}
              <Avatar
                alt="User"
                className={classes.avatar}
                src={"/static/images/avatars/avatar_default.png"}
              />
            {/*</RouterLink>*/}
          </Box>
          <Box
            mt={2}
            textAlign="center"
          >
            <Link
              component={RouterLink}
              to="/app/account"
              variant="h5"
              color="textPrimary"
              underline="none"
            >
              {/*{`${user.firstName} ${user.lastName}`}*/}
              {user.username}
            </Link>
            <Typography
              variant="body2"
              color="textSecondary"
            >
              {user.email}
            </Typography>
          </Box>
        </Box>
        <Divider />
        {/*{*/}
        {/*  user.branches_user_infos ? user.branches_user_infos.map((user_info, idx) => {*/}
        {/*      const _navConfig = _.cloneDeep(navConfig);*/}
        {/*      if (user_info.franchise_branch_id == null || user_info.franchise_branch_id === '') {*/}
        {/*        return (<Box p={2}>*/}
        {/*          {*/}
        {/*            _navConfig.map((config) => {*/}
        {/*              const _items = [...config.items];*/}
        {/*              for (let i = 0; i < _items.length; i++) {*/}
        {/*                const tmp_url = _items[i].href;*/}
        {/*                _items[i].href = tmp_url + "/" + user_info.rp_cid + "/";*/}
        {/*              }*/}
        {/*              return (*/}
        {/*                <List*/}
        {/*                  key={config.subheader}*/}
        {/*                  subheader={(*/}
        {/*                    <ListSubheader*/}
        {/*                      disableGutters*/}
        {/*                      disableSticky*/}
        {/*                      style={{fontWeight:'bold'}}*/}
        {/*                    >*/}
        {/*                      {user_info.service_info || 'Franchise Headquarter'}*/}
        {/*                    </ListSubheader>*/}
        {/*                  )}*/}
        {/*                >*/}
        {/*                  {renderNavItems({items: _items, pathname: location.pathname})}*/}
        {/*                </List>*/}
        {/*              );*/}
        {/*            })*/}
        {/*          }*/}
        {/*          <Divider/>*/}
        {/*        </Box>);*/}
        {/*      } else {*/}
        {/*        return (<Box></Box>);*/}
        {/*      }*/}
        {/*    })*/}
        {/*    : (<Box></Box>)*/}
        {/*}*/}
        {
          user.branches_user_infos ? user.branches_user_infos.map((user_info, idx) => {
            console.log(user_info)
              const _navConfig = _.cloneDeep(navConfig);
              if( (user_info.franchise_comp_id != null && user_info.franchise_comp_id !== '') && (user_info.franchise_branch_id == null || user_info.franchise_branch_id === '') ){
                return (<Box></Box>);
              } else {
                return (<Box p={2}>
                  {
                    _navConfig.map((config) => {
                      const _items = [...config.items];
                      for(let i=0; i<_items.length; i++){
                        _items[i].href += "/" +user_info.rp_cid + "/";
                      }
                      return (
                        <List
                          key={config.subheader}
                          subheader={(
                            <ListSubheader
                              disableGutters
                              disableSticky
                            >
                              <Link
                                component={RouterLink}
                                to={_items[0].href}
                                variant="body2"
                                color="textSecondary"
                              >
                              {user_info.service_info || user_info.rp_cid}
                              </Link>
                            </ListSubheader>
                          )}
                        >
                          {renderNavItems({ items: _items, pathname: location.pathname })}
                        </List>
                      );
                    })
                  }
                  <Divider />
                </Box>);
              }
        })
            :
            (<Box p={2}>
              {navConfig.map((config) => {
                console.log(user.branches_user_infos)
                return (
                  <List
                    key={config.subheader}
                    subheader={(
                      <ListSubheader
                        disableGutters
                        disableSticky
                      >
                        {config.subheader}
                      </ListSubheader>
                    )}
                  >
                    {renderNavItems({ items: config.items, pathname: location.pathname })}
                  </List>
                );
              })}
              <Divider />
            </Box>)
        }

      </PerfectScrollbar>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="left"
          classes={{ paper: classes.mobileDrawer }}
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer
          anchor="left"
          classes={{ paper: classes.desktopDrawer }}
          open
          variant="persistent"
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
}

NavBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

const mapStateToProps = ({ account }) => ({
  user: account.user,
});

const mapDispatchToProps = dispatch =>
  bindActionCreators(
    {
    },
    dispatch
  );

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(NavBar);
