import React, { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { Link as RouterLink } from 'react-router-dom';
import clsx from 'clsx';
import PropTypes from 'prop-types';
import {
  AppBar,
  Box,
  Button,
  Divider,
  Toolbar,
  Hidden,
  Typography,
  makeStyles
} from '@material-ui/core';
import { APP_VERSION } from 'src/config';
import Logo from 'src/components/Logo';
import Cookies from 'js-cookie';
import { bindActionCreators } from 'redux';
import { loginByCookie } from '../../actions/accountActions';

const useStyles = makeStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.background.default
  },
  toolbar: {
    height: 64
  },
  logo: {
    marginRight: theme.spacing(2)
  },
  link: {
    fontWeight: theme.typography.fontWeightMedium,
    '& + &': {
      marginLeft: theme.spacing(2)
    }
  },
  divider: {
    width: 1,
    height: 32,
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2)
  }
}));

function usePrevious(value) {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  }, [value]);
  return ref.current;
}

function TopBar({ account, className, ...rest }) {
  const classes = useStyles();
  const dispatch = useDispatch();

  useEffect(() => {
    if (window.hasOwnProperty('posnetApi')) {
      window.posnetApi.buildPosnetSignInButton();
    }
  });

  return (
    <AppBar
      className={clsx(classes.root, className)}
      color="default"
      {...rest}
    >
      <Toolbar className={classes.toolbar}>
        <RouterLink to="/">
          <Logo className={classes.logo} />
        </RouterLink>
        <Hidden mdDown>
          <Typography
            variant="caption"
            color="textSecondary"
          >
            Version
            {' '}
            {APP_VERSION}
          </Typography>
        </Hidden>
        <Box flexGrow={1} />
        {/* <Box */}
        {/*  component="div" */}
        {/*  display="inline" */}
        {/*  className="posnet-signin2" */}
        {/*  data-posnet-onsuccess="onSignIn" */}
        {/*  // onClick={event => console.log(event.target.dataset)} */}
        {/* > */}
        {/*  Login */}
        {/* </Box> */}
        {/* <Link */}
        {/*  className={classes.link} */}
        {/*  color="textSecondary" */}
        {/*  component={RouterLink} */}
        {/*  to="/app" */}
        {/*  underline="none" */}
        {/*  variant="body2" */}
        {/* > */}
        {/*  Dashboard */}
        {/* </Link> */}
        { (localStorage.getItem('posnet_access_token') === null) && (
        <Box
          component="div"
          display="inline"
          className="posnet-signin2"
          data-posnet-onsuccess="onSignIn"
          color="textSecondary"
        />
        )}
        {/* <Link */}
        {/*  className={classes.link} */}
        {/*  color="textSecondary" */}
        {/*  component={RouterLink} */}
        {/*  to="/docs" */}
        {/*  underline="none" */}
        {/*  variant="body2" */}
        {/* > */}
        {/*  Documentation */}
        {/* </Link> */}
        <Divider className={classes.divider} />
        { (localStorage.getItem('posnet_access_token') !== null) && (
        <Button
          color="secondary"
          component={RouterLink}
          to="/app"
          // component="a"
          // href="https://material-ui.com/store/items/devias-kit-pro"
          variant="contained"
          size="small"
        >
          Dashboard
        </Button>
        )}
      </Toolbar>
    </AppBar>
  );
}

TopBar.propTypes = {
  className: PropTypes.string
};

const mapStateToProps = ({ account }) => ({
  account,
  company_info: account.company_info,
  store_infos: account.store_infos,
});

const mapDispatchToProps = (dispatch) => bindActionCreators(
  {
  },
  dispatch
);

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TopBar);
// export default TopBar;
