import axiosArtApi from 'src/utils/axiosArtApi';
import authService from 'src/services/authService';

export const LOGIN_REQUEST = '@account/login-request';
export const LOGIN_SUCCESS = '@account/login-success';
export const LOGIN_FAILURE = '@account/login-failure';
export const SILENT_LOGIN = '@account/silent-login';
export const LOGOUT = '@account/logout';
export const REGISTER = '@account/register';
export const UPDATE_PROFILE = '@account/update-profile';

export function logout() {
  return (dispatch) => {
    authService.logout();
    dispatch({
      type: LOGOUT
    });
  };
}
export function loginByCookie(userName, accessCode) {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('posnet_access_token')}` }
  };
  return (dispatch) => axiosArtApi.get(`/report/profileGet/${userName}/`, config)
    .then((response) => {
      const { data } = response;
      if (data != null) {
        dispatch({ type: LOGIN_REQUEST });
        dispatch({
          type: LOGIN_SUCCESS,
          payload: {
            user: {
              ...data,
              canHire: false,
              country: data.country,
              email: data.user_name,
              firstName: data.firstName,
              isPublic: false,
              lastName: data.lastName,
              phone: data.phone,
              state: data.state,
              avatar: '/static/images/avatars/avatar_6.png',
              bio: 'Sales Manager', // `${data.firstName} ${data.lastName}`, // 'Sales Manager',
              password: data.user_name,
              timezone: '4:32PM (GMT-4)',
              accessToken: localStorage.getItem('posnet_access_token'),
              service_info: data.service_info,
              poble_email: data.poble_email,
              poble_company_info_id: data.poble_company_info_id,
              poble_store_info_id: data.poble_store_info_id,
              franchise_email: data.franchise_email,
              franchise_comp_id: data.franchise_comp_id,
              franchise_branch_id: data.franchise_branch_id,
              rp_cid: data.rp_cid,
            }
          }
        });
        authService.setSession(accessCode);
      }
    });
}
// export function loginByCookie(data, accessToken) {
//   return (dispatch) => {
//     // dispatch({ type: LOGIN_REQUEST });
//     dispatch({
//       type: LOGIN_SUCCESS,
//       payload: {
//         user: {
//           ...data,
//           canHire: JSON.parse(data.canHire),
//           country: data.country,
//           email: data.user_name,
//           firstName: data.firstName,
//           isPublic: JSON.parse(data.isPublic),
//           lastName: data.lastName,
//           phone: data.phone,
//           state: data.state,
//           avatar: '/static/images/avatars/avatar_6.png',
//           bio: 'Sales Manager', // `${data.firstName} ${data.lastName}`, // 'Sales Manager',
//           password: data.user_name,
//           timezone: '4:32PM (GMT-4)',
//           accessToken: localStorage.getItem('posnet_access_token'),
//           poble_email: data.poble_email,
//           poble_company_info_id: data.poble_company_info_id,
//           poble_store_info_id: data.poble_store_info_id,
//           franchise_email: data.franchise_email,
//           franchise_comp_id: data.franchise_comp_id,
//           franchise_branch_id: data.franchise_branch_id,
//           rp_cid: data.rp_cid,
//         }
//       }
//     });
//     authService.setSession(accessToken);
//   };
// }

export function login(email, password) {
  return async (dispatch) => {
    try {
      dispatch({ type: LOGIN_REQUEST });
      const user = await authService.loginWithEmailAndPassword(email, password);
      dispatch({
        type: LOGIN_SUCCESS,
        payload: {
          user
        }
      });
      // history.push('/app/products');
    } catch (error) {
      dispatch({ type: LOGIN_FAILURE });
      throw error;
    }
  };
}

export function setUserData(user) {
  return (dispatch) => dispatch({
    type: SILENT_LOGIN,
    payload: {
      user
    }
  });
}

export function register() {
  return true;
}

export function updateProfile(params) {
  const _data = new FormData();
  _data.set('user_name', params.user_name);
  _data.set('rp_cid', params.rp_cid);
  _data.set('firstName', params.firstName);
  _data.set('lastName', params.lastName);
  _data.set('email', params.email);
  _data.set('phone', params.phone);
  _data.set('franchise_comp_id', params.franchise_comp_id);
  _data.set('franchise_branch_id', params.franchise_branch_id);

  const request = axiosArtApi.post(`/report/account/profileUpdate/`, _data, {
    headers: { 'Content-Type': 'multipart/form-data' }
  });
  return (dispatch) => {
    dispatch({ type: LOGIN_REQUEST });
    request.then((response) => {
      const { data } = response;
      dispatch({
        type: UPDATE_PROFILE,
        payload: {
          user: {
            ...data,
            canHire: false,
            isPublic: false,
          }
        }
      });
    });
  };
}
