import jwtDecode from 'jwt-decode';
import axios from 'src/utils/axios';
import axiosProd from 'src/utils/axiosProd';
import axiosArtApi from 'src/utils/axiosArtApi';
import jwt from 'jsonwebtoken';
import Cookies from 'js-cookie';

class AuthService {
  setAxiosInterceptors = ({ onLogout }) => {
    axios.interceptors.response.use(
      (response) => response,
      (error) => {
        if (error.response && error.response.status === 401) {
          this.setSession(null);
          if (onLogout) {
            onLogout();
          }
        }

        return Promise.reject(error);
      }
    );
  };

  handleAuthentication() {
    const accessToken = this.getAccessToken();

    if (!accessToken) {
      return;
    }

    if (this.isValidToken(accessToken)) {
      this.setSession(accessToken);
    } else {
      this.setSession(null);
    }
  }



  loginWithEmailAndPassword = (email, password) => new Promise((resolve, reject) => {
    // '/api/account/login'
    axiosProd.post('/pos/getUserInfo', { username: email, password })
      .then((response) => {
        if (response.data.status === 'success') {
        // TODO: Handling Login Session Module
          const JWT_SECRET = 'devias-top-secret-key';
          const JWT_EXPIRES_IN = '2 days';

          const accessToken = jwt.sign(
            { id: '5e86809283e28b96d2d38537' },
            JWT_SECRET,
            { expiresIn: JWT_EXPIRES_IN }
          );

          const user = {
            id: '5e86809283e28b96d2d38537',
            avatar: '/static/images/avatars/avatar_6.png',
            bio: 'Sales Manager',
            canHire: false,
            country: 'USA',
            email,
            username: email,
            password,
            firstName: 'Katarina',
            isPublic: true,
            lastName: 'Smith',
            phone: '+40 777666555',
            role: 'admin',
            state: 'New York',
            timezone: '4:32PM (GMT-4)',
            company_info: response.data.company_info,
            store_infos: response.data.store_infos,
            accessToken,
          }

          this.setSession(user.accessToken);
          resolve(user);
        } else {
          // reject(response.data.message);
        }
      })
      .catch((error) => {
        console.log(error)
        reject(error);
      });
  })

  loginInWithToken = () => new Promise((resolve, reject) => {
    axios.get('/api/account/me')
      .then((response) => {
        if (response.data.user) {
          resolve(response.data.user);
        } else {
          reject(response.data.error);
        }
      })
      .catch((error) => {
        reject(error);
      });
  })

  logout = () => {
    this.setSession(null);
  }

  setSession = (accessToken) => {
    if (accessToken) {
      localStorage.setItem('posnet_access_token', accessToken);
      axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    } else {
      localStorage.removeItem('posnet_access_token');
      localStorage.removeItem('posnet_user_name');
      delete axios.defaults.headers.common.Authorization;
    }
  }

  getAccessToken = () => localStorage.getItem('posnet_access_token');

  isValidToken = (accessToken) => {
    if (!accessToken) {
      return false;
    }

    const decoded = jwtDecode(accessToken);
    const currentTime = Date.now() / 1000;

    return decoded.exp > currentTime;
  }

  isAuthenticated = () => !!this.getAccessToken()
}

const authService = new AuthService();

export default authService;
