import React, { useEffect, useRef } from 'react';
import { makeStyles } from '@material-ui/core';
import Page from 'src/components/Page';
import {useDispatch} from "react-redux";
import { loginByCookie } from 'src/actions/accountActions';
import {useHistory} from "react-router";
import axiosArtApi from "../../../utils/axiosArtApi";
import LoadingScreen from '../../../components/LoadingScreen';
import moment from 'moment';
import { updateSearchDate } from '../../../actions/reportActions';

const useStyles = makeStyles(() => ({
  root: {}
}));
const useComponentDidMount = func => useEffect(func, []);

const useComponentWillMount = willMountCallback => {
  const willMount = useRef(true);
  if (willMount.current) {
    willMountCallback();
  }
  useComponentDidMount(() => {
    willMount.current = false;
  });
};

function HomeView(props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();

  // new URLSearchParams(this.props.location.search).get("__firebase_request_key")
  const parms = new URLSearchParams(props.location.search)
  // Initialize SearchDate when login from homePage
  dispatch(updateSearchDate(moment().format('YYYY-MM-DD'), moment().format('YYYY-MM-DD')));
  if (parms.get('posnet_user_name') !== null && parms.get('posnet_access_token') !== null) {
    console.log(1111)
    const userName = parms.get('posnet_user_name');
    const accessCode = parms.get('posnet_access_token');
    localStorage.setItem('posnet_user_name', userName);
    localStorage.setItem('posnet_access_token', accessCode);
    dispatch(loginByCookie(userName, accessCode));
    history.push('/app/rp/dashboard');
  } else if (localStorage.getItem('posnet_user_name') !== null && localStorage.getItem('posnet_access_token') !== null){
    console.log(2222)
    const userName = localStorage.getItem('posnet_user_name');
    const accessCode = localStorage.getItem('posnet_access_token');
    dispatch(loginByCookie(userName, accessCode));
    history.push('/app/rp/dashboard');
  } else {
    console.log(33333)
    // If not login, redirect to posnet or login page
    history.push('/redirect-login');
  }
  // history.push('/redirect-login');



  // let redirect_url = "/redirect-home";
  // useComponentWillMount(() => {
  //   const parms = new URLSearchParams(window.location.search)
  //   if (parms.get('posnet_user_name') !== null && parms.get('posnet_access_token') !== null) {
  //     const userName = parms.get('posnet_user_name');
  //     const accessCode = parms.get('posnet_access_token');
  //     localStorage.setItem('posnet_user_name', userName);
  //     localStorage.setItem('posnet_access_token', accessCode);
  //     const config = { headers: { Authorization: `Bearer ${accessCode}` } };
  //     axiosArtApi.get(`/report/profileGet/${userName}/`, config)
  //       .then((response) => {
  //         if(response.data.length !== 0){
  //           dispatch(loginByCookie(response.data, accessCode));
  //           redirect_url = "/app/rp/dashboard";
  //           history.push('/app/rp/dashboard');
  //         } else {
  //           window.alert("No REPORT License");
  //           history.push('/redirect-home');
  //         }
  //       })
  //   } else if (localStorage.getItem('posnet_user_name') !== null && localStorage.getItem('posnet_access_token')){
  //     const userName = localStorage.getItem('posnet_user_name');
  //     const accessCode = localStorage.getItem('posnet_access_token');
  //     const config = { headers: { Authorization: `Bearer ${accessCode}` } };
  //     axiosArtApi.get(`/report/profileGet/${userName}/`, config)
  //       .then((response) => {
  //         if(response.data.length !== 0){
  //           dispatch(loginByCookie(response.data, accessCode));
  //           redirect_url = "/app/rp/dashboard";
  //           history.push('/app/rp/dashboard');
  //         } else {
  //           window.alert("No REPORT License");
  //           history.push('/redirect-home');
  //         }
  //       })
  //   } else {
  //     history.push('/redirect-home');
  //   }
  // });

  return (
    <Page
      className={classes.root}
      title="Home"
    >
      <LoadingScreen />
    </Page>
  );
}

export default HomeView;
