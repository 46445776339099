/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  UPDATE_SEARCH_DATE
} from 'src/actions/reportActions';
import moment from "moment";

const initialState = {
  search_start_date: moment().format('YYYY-MM-DD'),
  search_end_date: moment().format('YYYY-MM-DD')
};

const reportReducer = (state = initialState, action) => {
  switch (action.type) {
    case UPDATE_SEARCH_DATE: {
      const { search_start_date, search_end_date } = action.payload;

      return produce(state, (draft) => {
        draft.search_start_date = search_start_date;
        draft.search_end_date = search_end_date;
      });
    }

    default: {
      return state;
    }
  }
};

export default reportReducer;
